html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--color-secondary);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container button {
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
}

::-webkit-scrollbar {
  display: none;
}

.customSnackbar {
  z-index: 10000 !important;
}