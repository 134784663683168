
/* * {
  font-family: 'Roboto Mono' !important;
} */

@font-face {
  font-family: 'GilroyRegular';
  src: url('./fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GilroyMedium';
  src: url('./fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: medium;
  font-style: medium;
}

@font-face {
  font-family: 'GilroySemiBold';
  src: url('./fonts/Gilroy-SemiBold.ttf') format('truetype');
  font-weight:bolder;
  font-style:normal;
}

body {
  font-family: 'GilroyRegular', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  /* zoom:75% */
}

@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}